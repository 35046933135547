.header {
  background-color: #252526;
  padding: 1rem 4rem;
  display: flex;
  gap: 15px;
  color: white;
  justify-content: space-between;
  align-items: center;
  @media only screen and (max-width: 1100px) {
    padding: 1rem 1.5rem;
  }
}

.header-button {
  border: 2px #da382b solid;
  border-radius: 18px;
  padding: 0.3rem 1.25rem;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
}
