body {
  margin: 0;
  font-family: 'Gotham';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size-adjust: none;
  text-size-adjust: none;
  -webkit-text-size-adjust: none;
}

@font-face {
  font-family: Gotham;
  src: url("./fonts/Gotham-Book.otf");
  font-weight: 600;
}

@font-face {
  font-family: Gotham;
  src: url("./fonts/Gotham-Bold.otf");
  font-weight: 700;
}

@font-face {
  font-family: Gotham;
  src: url("./fonts/Gotham-Black.otf");
  font-weight: 900;
}