.footer {
  background-color: #252526;
  padding: 1rem 4rem;
  display: flex;
  gap: 16px;
  justify-content: space-between;
  color: white;
  @media only screen and (max-width: 1100px) {
    padding: 1rem 1.5rem;
  }
}

.footer-text {
  font-size: 12px;
  color: white;
  margin: 0;
}
