.home-inner-body {
  flex: 1;
  gap: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: black;
  background-image: url("../assets/background.svg");
  background-position: bottom;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  box-shadow: inset 0 -20px 50px -10px #000000;
}

.button {
  border: 2px #da382b solid;
  border-radius: 46px;
  padding: 0.3rem 3.5rem;
  font-size: 42px;
  font-weight: 900;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
}

.timeline-section {
  background-color: black;
  color: white;
  padding-top: 75px;
  padding-bottom: 75px;
}

.timeline-text {
  font-size: 24px;
  font-weight: 800;
  text-transform: uppercase;
}

.comming-text {
  font-size: 16px;
  text-transform: uppercase;
  color: #30a6cd;
}

.text-section-1 {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.section-1-title {
  font-size: 16px;
  text-transform: uppercase;
  color: white;
}

.period-title {
  font-size: 16px;
  text-transform: capitalize;
  color: white;
  margin: 0;
}

.period-text {
  font-size: 32px;
  font-weight: 700;
  text-transform: capitalize;
  color: #30a6cd;
  margin: 0;
}

.section-2-background {
  width: 550px;
  height: 550px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("../assets/circle.svg");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.text-section-2 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.section-2-title {
  font-size: 22px;
  font-weight: 700;
  text-transform: uppercase;
  color: #30a6cd;
  margin: 0;
  margin-bottom: 16px;
  margin-left: 19px;
}

.section-2-text {
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
  margin: 0;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 14px solid #bc2b81;
}

.sections-container {
  width: 100%;
  display: flex;
  gap: 128px;
  align-items: center;
  justify-content: center;
  @media only screen and (max-width: 1100px) {
    flex-direction: column;
  }
}

.text-section-3 {
  font-size: 18px;
  font-weight: 700;
  color: white;
  margin: 0 10px;
}

.circle1 {
  @media only screen and (min-width: 1100px) {
    display: none;
  }
}

.circle2 {
  @media only screen and (max-width: 1100px) {
    display: none;
  }
}

.text-section-3-head {
  @media only screen and (max-width: 1100px) {
    font-size: 32px;
    display: block;
    margin-bottom: 32px;
    max-width: 350px;
  }
}
